import { AccessabilityDetection } from './accessability-detection';
import { IComponentOptions } from './component';
import { ComponentFactory, GenericComponentFactory, IComponentFactoryOptions } from './component-factory';
import { ComponentManager } from './component-manager';
import { CalendarComponent, ICalendarOptions } from './components/calendar';
import { CalendarDayComponent, ICalendarDayEvent } from './components/calendar-day';
import { CollapsableComponent, CollapsableComponentFactory } from './components/collapsable';
import { CompassComponent, CompassComponentFactory, ICompassComponentOptions } from './components/compass';
import { ContentHeaderComponent, IContentHeaderOptions } from './components/content-header';
import { ContentHeaderItem } from './components/content-header-item';
import { DateComponent, IDateComponentOptions } from './components/date';
import { DrawerComponent } from './components/drawer';
import { DropdownComponent, IDropdownComponentOptions } from './components/dropdown';
import { IListComponentOptions, ListComponent } from './components/list';
import { ListItem } from './components/list-item';
import { ListOption } from './components/list-option';
import { LiveRegion } from './components/live-region';
import { IModalOptions } from './components/modal';
import { IPaginationComponentOptions, PaginationComponent } from './components/pagination';
import { PopoverComponent, PopoverComponentFactory } from './components/popover';
import { PromptComponent } from './components/prompt';
import { ISyntheticButtonComponentOptions, SyntheticButtonComponent } from './components/synthetic-button';
import { TabComponent } from './components/tab';
import { TabListComponent, TabListComponentFactory } from './components/tab-list';
import { TabNavigationComponent } from './components/tab-navigation';
import { ITooltipComponentOptions, TooltipComponent } from './components/tooltip';
import { logger } from './logger';
import { RegionSettings } from './regional';

import * as dateUtility from './date-util';
import * as dom from './dom';
import * as keyPressHandlers from './keypress-handler';
import * as utility from './utility';

// tslint:disable-next-line interface-name
export interface AlkamiFramework {
	Iris: IrisFramework;
}

const _global = ((typeof window !== 'undefined') ? window : global) as any;


export class IrisFramework {
	public AccessabilityDetection = AccessabilityDetection;
	public CollapsableComponent = CollapsableComponent.factory;
	public CompassComponent = CompassComponent.factory;
	public ContentHeader = ContentHeaderComponent.factory;
	public ContentHeaderComponent = ContentHeaderComponent.factory;
	public ContentHeaderItem = ContentHeaderItem;
	public DateComponent = DateComponent.factory;
	public DropdownComponent = DropdownComponent.factory;
	public Drawer = DrawerComponent.factory;
	public DrawerComponent = DrawerComponent.factory;
	public ListComponent = ListComponent.factory;
	public PaginationComponent = PaginationComponent.factory;
	public PopoverComponent = PopoverComponent.factory;
	public Prompt = PromptComponent.factory;
	public PromptComponent = PromptComponent.factory;
	public SyntheticButtonComponent = SyntheticButtonComponent.factory;
	public TabListComponent = TabListComponent.factory;
	public TooltipComponent = TooltipComponent.factory;

	public CalendarComponent = CalendarComponent.factory;
	public CalendarDayComponent = CalendarDayComponent;
	public ListItem = ListItem;
	public ListOption = ListOption;
	public LiveRegion = LiveRegion;
	public RegionSettings = RegionSettings;
	public TabComponent = TabComponent;
	public TabNavigationComponent = TabNavigationComponent;

	public ComponentFactory = ComponentFactory;
	public ComponentManager = ComponentManager.defaultManager;
	public GenericComponentFactory = GenericComponentFactory;

	public dateUtility = dateUtility;
	public dom = dom;
	public keyPressHandlers = keyPressHandlers;
	public logger = logger;
	public utility = utility;
	public readonly version = process.env.IRIS_VERSION;

	public init = function() {
		// Iris initialization begin
		// =============================================================================
		dom.dispatchEvent(document.body, 'iris.init.start', { iris: this });
		dom.dispatchEvent(document.body, 'irisinitstart', { iris: this });

		this._accessabilityDetection = new AccessabilityDetection();

		// Component Initialization
		// =============================================================================
		dom.dispatchEvent(document.body, 'iris.init.component-start', { iris: this });

		if (this._irisOptions.autoInit) {
			this._initComponents();
		}

		dom.dispatchEvent(document.body, 'iris.init.component-end', { iris: this });

		logger({
			message: `Initialized Iris Framework version ${this.version}.`,
			type: 'info',
		});

		// Iris initialization end
		// =============================================================================
		dom.dispatchEvent(document.body, 'irisinitend', { iris: this });
		dom.dispatchEvent(document.body, 'iris.init.end', { iris: this });
	};

	// This method takes the default query selector of a component and filters out the
	// ones intended to be manually init-ed.
	private _getAutoInitElements(querySelector: string): NodeListOf<HTMLElement> {
		const filteredElementQuery = `${querySelector}:not([data-manual-init])`;

		return document.querySelectorAll(filteredElementQuery);
	}

	private _initComponents() {
		this.CollapsableComponent.init(this._getAutoInitElements(this.CollapsableComponent.querySelector));
		this.CompassComponent.init(this._getAutoInitElements(this.CompassComponent.querySelector));
		this.DateComponent.init(this._getAutoInitElements(this.DateComponent.querySelector));
		this.DropdownComponent.init(this._getAutoInitElements(this.DropdownComponent.querySelector));
		this.ListComponent.init(this._getAutoInitElements(this.ListComponent.querySelector));
		this.PromptComponent.init(this._getAutoInitElements(this.PromptComponent.querySelector));
		this.DrawerComponent.init(this._getAutoInitElements(this.DrawerComponent.querySelector));
		this.SyntheticButtonComponent.init(this._getAutoInitElements(this.SyntheticButtonComponent.querySelector));
		this.TabListComponent.init(this._getAutoInitElements(this.TabListComponent.querySelector));

		if (typeof Popper === 'undefined') {
			logger({
				message: 'Popper.js is not available. It is required by the Iris Framework to use the Popover and Tooltip Components.',
				type: 'warn',
			});
		} else {
			this.PopoverComponent.init(this._getAutoInitElements(this.PopoverComponent.querySelector));
			this.TooltipComponent.init(this._getAutoInitElements(this.TooltipComponent.querySelector));
		}
	}

	private _accessabilityDetection: AccessabilityDetection = null;
	private _irisOptions = {
		autoInit: true,
	};

	public get globalOptions() {
		return this._irisOptions;
	}

	public set globalOptions(newOptions: object) {
		this._irisOptions = Object.assign(this._irisOptions, newOptions);
	}

	public static Iris: IrisFramework = new IrisFramework();
}

export function globalizeIris() {
	_global.Alkami = _global.Alkami || {} as AlkamiFramework;
	_global.Alkami.Iris = IrisFramework.Iris;
}
export function unglobalizeIris() {
	delete _global.Alkami.Iris;
}

export const Iris = IrisFramework.Iris;

export default Iris;

globalizeIris();
if (_global.window) {
	const iris = IrisFramework.Iris;
	Iris.dom.ready(() => { iris.init(); });
}
